<template>
  <div class="participant-records-table">
    <table-title>
      <p>
        會員紀錄 <span class="unit">數量：{{ total }}</span>
      </p>
    </table-title>

    <el-table :data="record_data">
      <el-table-column
        label="出席"
        fixed="left"
        prop="attend"
        width="80"
        sortable
      >
        <template v-slot="{ row }">
          <div v-if="row.attend" style="color: var(--success-color)">出席</div>
          <div v-else style="color: var(--danger-color)">缺席</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="participant_record_id"
        label="ID"
        sortable
      ></el-table-column>
      <el-table-column
        prop="activity.title_zh"
        label="活動名稱"
        sortable
      ></el-table-column>
      <el-table-column label="編輯時間" prop="created_at" width="200" sortable>
        <template v-slot="{ row }">
          {{ $formatTime(row.updated_at) }}
        </template>
      </el-table-column>
      <el-table-column label="創建時間" prop="created_at" width="200" sortable>
        <template v-slot="{ row }">
          {{ $formatTime(row.created_at) }}
        </template>
      </el-table-column>
    </el-table>

    <table-pagination
      :total="total"
      :limit="setup.limit"
      :page="setup.page"
      @change-page="handleChangePage"
    ></table-pagination>
  </div>
</template>

<script>
import { getParticipantRecordsPagination } from "@/api/participant";

export default {
  name: "Records",
  data() {
    return {
      record_data: [],
      total: 0,
      setup: {
        page: 0,
        limit: 25,
        search: "",
      },
    };
  },
  computed: {
    participantId() {
      return +this.$route.params.id;
    },
  },
  created() {
    this.handleGetParticipantRecords();
  },
  methods: {
    async handleGetParticipantRecords() {
      const { results, total } = await getParticipantRecordsPagination(
        this.participantId,
        this.setup
      );

      this.record_data = results;
      this.total = total;
    },
    handleChangePage(page) {
      this.setup.page = page;

      this.handleGetSchedule();
    },
  },
};
</script>

<style lang="scss" scoped>
.participant-records-table {
  margin-top: 24px;
}
</style>
