<template>
  <div class="detail-participant-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Participant' }"
        >會員管理</el-breadcrumb-item
      >
      <el-breadcrumb-item>會員內容</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <div class="flex-end" style="padding: 12px 0">
        <el-button type="info" @click="show_detail = !show_detail">{{
          !show_detail ? "內容展開" : "內容收起"
        }}</el-button>
      </div>
      <div
        class="content text-center"
        v-if="!show_detail"
        @click="show_detail = !show_detail"
      >
        內容過多，請點擊展開
      </div>
      <el-form
        :model="participant_form"
        label-position="top"
        v-show="show_detail"
      >
        <el-form-item label="名稱" prop="name">
          <el-input
            v-model="participant_form.name"
            maxlength="256"
            disabled
          ></el-input>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="信箱" prop="username">
              <el-input
                v-model="participant_form.username"
                maxlength="256"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地址" prop="address">
              <el-input v-model="participant_form.address" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="職業" prop="job">
              <el-input v-model="participant_form.job" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="性別" prop="gender">
              <el-select v-model="participant_form.gender" disabled>
                <el-option value="other" label="其他"></el-option>
                <el-option value="male" label="男"></el-option>
                <el-option value="female" label="女"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年齡" prop="age">
              <el-select v-model="participant_form.age" disabled>
                <el-option value="18歲以下" label="18歲以下"></el-option>
                <el-option value="19-24歲" label="19-24歲"></el-option>
                <el-option value="25-34歲" label="25-34歲"></el-option>
                <el-option value="35-44歲" label="35-44歲"></el-option>
                <el-option value="45-54歲" label="45-54歲"></el-option>
                <el-option value="55-64歲" label="55-64歲"></el-option>
                <el-option value="65歲以上" label="65歲以上"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="登入方式" prop="login_type">
              <el-input
                v-model="participant_form.login_type"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="電話" prop="phone">
              <el-input v-model="participant_form.phone" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="備註" prop="note">
          <el-input
            type="textarea"
            v-model="participant_form.note"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect('Participant')"
              >回主頁</el-button
            >
            <el-button
              type="success"
              @click="handleRedirect('EditParticipant', scheduleId)"
              >編輯</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </div>

    <div class="container">
      <records></records>
    </div>
  </div>
</template>

<script>
import { getParticipant } from "@/api/participant";
import Records from "./components/Records.vue";

export default {
  name: "DetailParticipant",
  components: { Records },
  data() {
    return {
      participant_form: {
        username: "",
        name: "",
        gender: "",
        job: "",
        address: "",
        phone: "",
        note: "",
        login_type: "",
      },
      show_detail: false,
    };
  },
  computed: {
    participantId() {
      return +this.$route.params.id;
    },
  },
  created() {
    this.handleGetParticipant();
  },
  methods: {
    async handleGetParticipant() {
      const res = await getParticipant(this.participantId);

      Object.keys(this.participant_form).forEach((key) => {
        if (res[key]) {
          this.participant_form[key] = res[key];
        }
      });
    },
    handleRedirect(name, params) {
      this.$router.push({ name, params });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-participant-block {
  .content {
    margin: 12px 0;
    padding: 24px 0;
    border: 1px solid #606266;
    border-radius: 12px;
    color: var(--primary-gray-color);
    font-size: 14px;
    cursor: pointer;

    &:hover {
      border: 1px solid #409eff;
      color: #409eff;
    }
  }
}
</style>
